import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ForgetPassword from "../view/auth/ForgetPassword";
import Login from "../view/auth/Login";
import Home from "../view/home";
import BillsAndInvoices from "../view/orders/BillsAndInvoices";
import DispatchStatus from "../view/orders/DispatchStatus";
import OrderStatus from "../view/orders/OrderStatus";
import YourOrder from "../view/orders/YourOrder";
import ProtectedRoute from "./ProtectedRoute";
import MyProfile from "../view/adminProfile/UserProfile";
import ProtectedLayout from "../components/ProtectedLayout";
import Index from "../view/layout";
import Create from "../view/layout/create";
import UploadLayout from "../view/layout/UploadLayout";
import Designplan from "../view/layout/Designplan";
import CreateOrderPurches from "../view/orders/CreateOrderPurches";
import MaterialSelection from "../view/orders/MaterialSelection";
import ProductList from "../view/orders/ProductList";
import PlaceOrder from "../view/orders/PlaceOrder";
import OrderHistory from "../view/viewOrder/OrderHistory";
import ViewBillAndInvoice from "../view/viewOrder/ViewBillAndInvoice";
import CheckCart from "../view/home/CheckCart";

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedLayout />}>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/profile"
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />

          <Route
              path="/layout"
              element={
                <ProtectedRoute>
                  <Index />
                </ProtectedRoute>
              }
            />
            <Route
              path="/layout/create"
              element={
                <ProtectedRoute>
                  <Create />
                </ProtectedRoute>
              }
            />

          <Route
            path="/orders/status"
            element={
              <ProtectedRoute>
                <OrderStatus />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/bills-invoices"
            element={
              <ProtectedRoute>
                <BillsAndInvoices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/dispatch-status"
            element={
              <ProtectedRoute>
                <DispatchStatus />
              </ProtectedRoute>
            }
          />
            {/* <Route path="/layout" element={  <ProtectedRoute><Layout/></ProtectedRoute>}></Route> */}
          <Route
            path="/orders/created"
            element={
              <ProtectedRoute>
                <YourOrder />
              </ProtectedRoute>
            }
          />
           
          <Route path="/designplan" element={  <ProtectedRoute><Designplan/></ProtectedRoute>} />
          <Route path="/order" element={  <ProtectedRoute><CreateOrderPurches/></ProtectedRoute>} />
          <Route path="/order/material-selection" element={  <ProtectedRoute><MaterialSelection/></ProtectedRoute>} />
          <Route path="/order/product-list" element={  <ProtectedRoute><ProductList/></ProtectedRoute>} />
          <Route path="/order/place-order" element={  <ProtectedRoute><PlaceOrder/></ProtectedRoute>} />
          <Route path="/view-order" element={  <ProtectedRoute><OrderHistory/></ProtectedRoute>} />
          <Route path="/view-Order/viewBill" element={  <ProtectedRoute><ViewBillAndInvoice/></ProtectedRoute>} />
          <Route path="/check-order-cart/:id" element={  <ProtectedRoute><CheckCart/></ProtectedRoute>} />
       
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />


      </Routes>
    </BrowserRouter>
  );
}
