import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const outletListApi = createAsyncThunk(
    'layout/outletList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(
                '/api/v1/dealor/outlets/by-category/',
                data,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            );
            return response?.data;
         
        }
        catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const outletListSlice = createSlice({
    name: 'outletList',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(outletListApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(outletListApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(outletListApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const outletListReducer = outletListSlice.reducer