import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getRevisedSchematic } from '../../store/layout/revisedSchematic';
import { convertToFormData } from '../ConvertToFormData';
import { postRevisedLayoutView } from '../../store/layout/postRevisedLayoutView';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { handleDownload } from "../reusable/downloadFile";
import { showToast } from '../../toast/toast';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(name, Date, PaymentStatus, Invoice, Action) {
    return { name, Date, PaymentStatus, Invoice, Action };
}
const rows = [
    createData('1st Schematic', '10/01/24', 1, 1,),
    createData('Revised Schematic', '19/01/24', 1, 0,),
    createData('Revised Schematic', '18/01/24', 0, 0,),
];
export default function RevisedLayout({ setSelectedTab }) {

    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
 
    // schematicLayoutData
    // console.log(schematicData, "schematicData")
    console.log(formId, "formId");
    const dispatch = useDispatch()
    const [data, setData] = useState([]);
    console.log(data, "data");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = React.useState();

    const handleClickPopper = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const designEstimate = async () => {
        const formD = {
            'form_id': formId.id
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getRevisedSchematic(data));
            console.log(response, "response");
            setData(response?.payload?.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        designEstimate();
    }, []);



    const handleView = async (data) => {
        try {
            localStorage.setItem("isRevisedViewClick", 1);
            await dispatch(postRevisedLayoutView(data));
          setSelectedTab(4)
        } catch (error) {
           showToast(error, 2)
        }

    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography variant="h6" className="fs-17  fw-400">Schematic Revisions</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                        <Button className='layout-blue-btn'>
                            Schematic by KIA
                        </Button>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Estimate Type</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Schematic Send Date</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row">
                                            {row?.design_estimate?.estimate_type}
                                        </StyledTableCell>
                                        <StyledTableCell >{row.created_at.split("T")[0]}</StyledTableCell>
                                        <StyledTableCell >
                                            <Button className="DownloadBtn" aria-describedby={id} onClick={handleClickPopper('right')}>Download</Button>
                                    
                                            <Popper id={id} open={open} anchorEl={anchorEl} transition placement={placement}>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid',
                                                                borderColor: 'grey.300',
                                                                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                                                p: 1,
                                                                bgcolor: 'background.paper',
                                                                borderRadius: 0,
                                                                width: 320,
                                                                height:350,
                                                              
                                                             
                                                               
                                                            }}
                                                        >
                                                            {row?.schematic_pdf?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files} >
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={40} height={40} src={pdf} className="my-auto" alt="PDF" />
                                                                            <Typography>PDF File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`https://kiastageapi.dev.vinove.com${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                            {row?.schematic_cad?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files} >
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={40} height={40} src={pdf} className="my-auto" alt="CAD" />
                                                                            <Typography>CAD File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`https://kiastageapi.dev.vinove.com${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}

                                                            {row?.schematic_files?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files}>
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={40} height={40} src={pdf} className="my-auto" alt="CAD" />
                                                                            <Typography>Other File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`https://kiastageapi.dev.vinove.com${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    </Fade>
                                                )}
                                            </Popper>


                                            <Button className="DownloadBtn" onClick={() => handleView(row)}>View</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}