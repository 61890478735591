import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const addInputDrawingApi = createAsyncThunk(
    'layout/add-input-drawing',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(
                '/api/v1/layout/input_drawing/',
                data,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            );
            return response?.data;
        }
        catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const editInputDrawingApi = createAsyncThunk(
    'layout/edit-input-drawing',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(
                '/api/v1/layout/input_drawing/',
                data,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            );
            return response?.data;
        }
        catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const addInputDrawingSlice = createSlice({
    name: 'add-input-drawing',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(addInputDrawingApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(addInputDrawingApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(addInputDrawingApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
        builder.addCase(editInputDrawingApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editInputDrawingApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(editInputDrawingApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const addInputDrawingReducer = addInputDrawingSlice.reducer