import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

    export const getAllOutletApi = createAsyncThunk(
        'groupDealor/get-group-outlet',
        async (data, { rejectWithValue }) => {
            try {
                //dealor/filteroutletbydealergroup/
                const response = await AuthAxios.post(`/api/v1/dealor/filteroutletbydealergroup/`,data,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                console.log(response?.data,"res outlet");
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )

const getGroupOutletSlice = createSlice({
    name: 'groupDealer',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getAllOutletApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getAllOutletApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            console.log(action?.payload,"action?.payload");
            state.message = action?.payload?.message
        })
        builder.addCase(getAllOutletApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
        
    },
})

export const getAllOutletReducer = getGroupOutletSlice.reducer