import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const viewOrderHistory = createAsyncThunk(
    'auth/viewOrder',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/order/create-order/`,
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const viewOrderHistorySlice = createSlice({
    name: 'viewOrder',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(viewOrderHistory.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(viewOrderHistory.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(viewOrderHistory.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const viewOrderHistoryReducer = viewOrderHistorySlice.reducer