import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoWhite from "../assets/img/kialogowhite.png";
import Style from "../styles/drawer.module.scss";
import SpeedIcon from "@mui/icons-material/Speed";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BorderClear, Padding } from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { isHandleDrawer } from "../store/drawer";
import { useDispatch, useSelector } from "react-redux";
import { Height, TextSnippetRounded } from "@mui/icons-material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { logoutApi } from "../store/auth/login";

export default function Drawer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const {result:isHamburgerOpen}= useSelector((state) => state.drawerTypeReducer);

  const expandIconStyle = {
    ml: 7,
  };

  useEffect(() => {
    // Split the pathname into segments
    const segments = pathname.split("/").filter(Boolean);


    // Get the first segment of the URL path
    const firstSegment = segments[0];

    // Determine which accordion to expand based on the first segment
    if (firstSegment === "dealer") {
      setExpandedAccordion("dealerAccordion");
    } else if (firstSegment === "v") {
      setExpandedAccordion("phaseControllerAccordion");
    } else if (
      firstSegment === "users" ||
      firstSegment === "kin" ||
      firstSegment === "innoceans"
    ) {
      setExpandedAccordion("userManagementAccordion");
    }
  }, [pathname]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const closeHamburger = () => {
    dispatch(isHandleDrawer(!isHamburgerOpen))
  }
  const handleLogout = async () => {
    const newState = false;
    dispatch(isHandleDrawer(newState))
    dispatch(logoutApi())
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>

{isMobile ? (
        <Box className={isHamburgerOpen === true ? Style?.open : Style?.close}>
          <Box className={Style?.logo} onClick={() => navigate("/")}>
            <img src={LogoWhite} alt="logo"  onClick={closeHamburger}/>
            <HighlightOffOutlinedIcon className={Style.closemobile} sx={{Height: "30px", Width: "30px",color:"#ffffff"}} onClick={closeHamburger}/>
          </Box>
          <List sx={{ width: "100%", fontSize: "15px", fontWeight: "400" }}>

          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/dealer")
                ? Style.activeLink
                : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "dealerAccordion"}
              onChange={handleAccordionChange("dealerAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="dealerAccordion-content"
                id="dealerAccordion-header"
              >
                <SpeedIcon sx={{ mr: 1.5 }} />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  Home
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                }}
              >
                <Accordion
                  expanded={expandedAccordion === "dealerAccordion"}
                  onChange={handleAccordionChange("dealerAccordion")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <ArrowForwardIcon sx={{ mr: 1.5, fontSize: "18px" }} />
                    Dashboard
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ width: "100%", boxSizing: "border-box", py: 0 }}
                  >
                    <ul className={Style.menuUnstyledList}>
                      <li onClick={closeHamburger}>
                        <Link
                          className="linkLight"
                          active
                          to="/"
                        >
                          Dashboard
                        </Link>
                      </li>
                      {/* <li>
                        <Link className="linkLight" to="/orders/status">
                          Saved Invites
                        </Link>
                      </li> */}
                    </ul>
                  </AccordionDetails>
                </Accordion>
                
              </AccordionDetails>
            </Accordion>
          </ListItem>

          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
            {/* <Accordion sx={{ flexGrow: "1", width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
              >
                <DriveFileMoveIcon sx={{ mr: 1.5 }} />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  Phase Controller
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                }}
              >
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <ArrowForwardIcon sx={{ mr: 1.5, fontSize: "18px" }} />
                    Dealor Onboarding
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ width: "100%", boxSizing: "border-box", py: 0 }}
                  >
                    <ul className={Style.menuUnstyledList}>
                      <li>
                        <Link className="linkLight" to="/dealer/onboarding">
                          Applicant Database
                        </Link>
                      </li>
                      <li>
                        <Link className="linkLight" to="/orders/status">
                          Saved Invites
                        </Link>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                    borderTop: "1px solid #31404b",
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <ArrowForwardIcon sx={{ mr: 1.5, fontSize: "18px" }} />
                    Application Material Manage
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ width: "100%", boxSizing: "border-box", py: 0 }}
                  >
                    <ul className={Style.menuUnstyledList}>
                      <li>
                        <Link className="linkLight" to="/orders/purchase">
                          Upload/Modify
                        </Link>
                      </li>
                      <li>
                        <Link className="linkLight" to="/orders/status">
                          Access Management
                        </Link>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion> */}
          </ListItem>
          
          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/master") ? Style.activeLink : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "masterManagementAccordion"}
              onChange={handleAccordionChange("masterManagementAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="masterManagementAccordion-content"
                id="masterManagementAccordion-header"
              >
                <TextSnippetRounded sx={{ mr: 1.5 }} />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  Master Data Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                  px: 4,
                }}
              >
                <ul className={Style.menuUnstyledList}>
                  <li onClick={closeHamburger}>
                    <Link  className="linkLight" to="/master/department">
                      Department (KIA)
                    </Link>
                  </li>

                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/master/organization">
                      Supplier Organization
                    </Link>
                  </li>

                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/master/material">
                      Material Category
                    </Link>
                  </li>

                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/master/inventory">
                      Inventory Management
                    </Link>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "userManagementAccordion"}
              onChange={handleAccordionChange("userManagementAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="userManagementAccordion-content"
                id="userManagementAccordion-header"
              >
                <ManageAccountsIcon sx={{ mr: 1.5 }} />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  User Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                  px: 4,
                }}
              >
                <ul className={Style.menuUnstyledList}>
                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/users/dealer">
                      Dealer Group
                    </Link>
                  </li>
                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/users/dealer-outlet">
                      Dealer Outlet
                    </Link>
                  </li>
                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/users/kin">
                      KIA Users
                    </Link>
                  </li>
                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/users/innoceans">
                      Innocean Users
                    </Link>
                  </li>
                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/users/suppliers">
                      Supplier Users
                    </Link>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </ListItem>
          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/layout") ? Style.activeLink : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "layoutManagementAccordion"}
              onChange={handleAccordionChange("layoutManagementAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="layoutManagementAccordion-content"
                id="layoutManagementAccordion-header"
              >
                <ViewComfyOutlinedIcon sx={{ mr: 1.5 }}/>
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  Layout Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                  px: 4,
                }}
              >
                <ul className={Style.menuUnstyledList}>
                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/layout">
                      Layout Management
                    </Link>
                  </li>

                  
                </ul>
              </AccordionDetails>
            </Accordion>
          </ListItem>
          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/layout") ? Style.activeLink : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "orderMasteringAccordion"}
              onChange={handleAccordionChange("orderMasteringAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="orderMasteringAccordion-content"
                id="orderMasteringAccordion-header"
              >
                <WidgetsOutlinedIcon sx={{ mr: 1.5 }}/>
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
             Order Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                  px: 4,
                }}
              >
                <ul className={Style.menuUnstyledList}>
                  <li onClick={closeHamburger}>
                    <Link className="linkLight" to="/orders">
                      View Order
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="linkLight" to="/order/receipt">
                      Upload/View order receipt
                    </Link>
                  </li> */}
                </ul>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          <ListItem
            className={
              pathname === "/roles"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            disablePadding
          >
            <ListItemButton
              onClick={closeHamburger}
              sx={{ borderBottom: "1px solid rgba(42, 58, 70, 1)" }}
              component={Link}
              to="/roles"
            >
              <ListItemIcon sx={{minWidth:"52px !important"}}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Roles & Access Management"
                sx={{
                  ml: -1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{ p: 0 }}            
            onClick={() => {
              closeHamburger();
              navigate('/user/profile')
            }}
          >
            <Accordion
              
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
               sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                
              >
              <AccountCircleIcon sx={{ mr: 1.5 }} />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                  
                >
                  Profile
                </Typography>
              </AccordionSummary>
              
            </Accordion>
          </ListItem>
          <ListItem
            sx={{ p: 0 }}            
            onClick={() => {
              handleLogout();
            }}
          >
            <Accordion
              
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
               sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                
              >
              <LogoutIcon sx={{ mr: 1.5 }}/>
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                  
                >
                  Logout
                </Typography>
              </AccordionSummary>
              
            </Accordion>
          </ListItem>
        </List>
      </Box>
        
      ) : (
      <Box className={Style.open}>
        <Box className={Style?.logo} onClick={() => navigate("/")}>
          <img src={LogoWhite} alt="logo" />
        </Box>
        <List sx={{ width: "100%", fontSize: "15px", fontWeight: "400" }}>
             <ListItem
            className={
              pathname === "/"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            disablePadding
          >
            <ListItemButton
              sx={{ borderBottom: "1px solid rgba(42, 58, 70, 1)" }}
              component={Link}
              to="/"
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                sx={{
                  ml: -1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
          </ListItem>
          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "userManagementAccordion"}
              onChange={handleAccordionChange("userManagementAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="userManagementAccordion-content"
                id="userManagementAccordion-header"
              >
                <DriveFileMoveIcon sx={{ mr: 1.5 }} />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  CI/SI 2.0 Order Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                  px: 4,
                }}
              >
                <ul className={Style.menuUnstyledList}>
                  {/* <li>
                    <Link className="linkLight" to="/layout-management">
                      Layout management
                    </Link>
                  </li> */}
                  <li>
                    <Link className="linkLight" to="/layout">
                      Layout Management
                    </Link>
                  </li>
                  <li>
                    <Link className="linkLight" to="/order">
                    Create Purchase Order 
                    </Link>
                  </li>
                  <li>
                    <Link className="linkLight" to="/view-order">
                      View Orders
                    </Link>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </ListItem>

         
          <ListItem
            className={
              pathname === "/user/profile"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            disablePadding
          >
            <ListItemButton
              sx={{ borderBottom: "1px solid rgba(42, 58, 70, 1)" }}
              component={Link}
              to="/user/profile"
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dealer Profile"
                sx={{
                  ml: -1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

     
        </List>
      </Box>
      )}
    </>
  );
}