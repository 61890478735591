import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import { convertToFormData } from "../ConvertToFormData";
import { useDispatch, useSelector } from "react-redux";
import { gfcLayout } from "../../store/layout/gfc";
import { handleDownload } from "../reusable/downloadFile";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import GfcRequestForRevision from "../reusable/GfcRequestForRevision.jsx";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import pdf from '../../assets/img/pdf.png';
import cad from '../../assets/img/cad.png';
import { showToast } from "../../toast/toast.jsx";
import { approveGFC } from "../../store/layout/approveGFC.js";

export default function Gfc() {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const gfcId =useSelector((state) => state?.gfcRevisedReducer?.result[0]?.id);
    const {result : revisedGFCData} = useSelector((state) => state?.revisedGFCViewReducer);
    const {result : GFCData, loading : GFCLoading} = useSelector((state) => state?.gfcReducer)
      const [data, setData] = useState(localStorage.getItem("isRevisedGFCViewClick") == 1 ? revisedGFCData : GFCData);   
 
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getGfcLayout = async () => {
        const formD = {
            'form_id': formId?.id
        };
        const formData = convertToFormData(formD);
        try {
            const response = await dispatch(gfcLayout(formData));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (formId?.id) {
            getGfcLayout();
        }
    }, [formId]);

    const approveGFCAction = async () => {
        const formData = convertToFormData({ 'gfc_id': gfcId });
        try {
            const response = await dispatch(approveGFC(formData)); 
            showToast(response?.payload?.message, 1);
            console.log(response, "Response from sendToKin");
        } catch (error) {
            console.error("Error in sending data to Kin:", error);
        }
    };

    useEffect(() => {
        const isRevisedGFCViewClick = localStorage.getItem("isRevisedGFCViewClick");
        setData(isRevisedGFCViewClick === '1' ? revisedGFCData : GFCData);
      }, [revisedGFCData, GFCData]);

    return (
        <>{data?.approve_by_admin===true ?(    <>
            <Box className="h-100 d-flex flex-column justify-content-between">
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                        {data?.gfc_pdf && data.gfc_pdf.length > 0 && (
                            <Box sx={{ width: "213px" }}>
                                <Box className="upload-box bg-white text-center">
                                    <Box className="m-auto p-relative">
                                        <Tooltip title={data.gfc_pdf[0].files} placement="top" className="uploadedfilename">
                                            <img width={40} height={40} src={pdf} className="my-auto" alt="PDF File" />
                                            <Typography>PDF File</Typography>
                                            <DownloadForOfflineRoundedIcon
                                                className="my-auto"
                                                onClick={() => handleDownload(new Date() + 'schematic_cad', data?.gfc_pdf[0]?.files)}
                                            />
                                            <FullscreenIcon
                                                className="my-auto"
                                                onClick={() => window.open(`https://kiastageapi.dev.vinove.com${data?.gfc_pdf[0]?.files}`)}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                            {data?.gfc_cad && data.gfc_cad.length > 0 && (
                            <Box sx={{ width: "213px" }}>
                                <Box className="upload-box bg-white text-center">
                                    <Box className="m-auto p-relative">
                                        <Tooltip title={data.gfc_cad[0].files} placement="top" className="uploadedfilename">
                                            <img width={40} height={40} src={cad} className="my-auto" alt="CAD File" />
                                            <Typography>CAD File</Typography>
                                            <DownloadForOfflineRoundedIcon
                                                className="my-auto"
                                                onClick={() => handleDownload(new Date() + 'schematic_cad', data.gfc_cad[0].files)}
                                            />
                                            <FullscreenIcon
                                                className="my-auto"
                                                onClick={() => window.open(`https://kiastageapi.dev.vinove.com${data.gfc_cad[0].files}`)}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                        )}    
                    </Box>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "23px" }}>
                        <Button className="black-btn" onClick={approveGFCAction}>Approve</Button>
                        <Button className="white-btn" onClick={handleOpen}>Request for revision</Button>
                    </Grid>
                </Grid>
            </Box>
            <GfcRequestForRevision open={open} handleClose={handleClose} gfc={gfcId}/>
        </>):(<div className="d-flex h-100"><p className="m-auto">No data found or It is not approved</p></div>)}</>
    
    );
}