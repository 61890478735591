import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import checked from "../../assets/img/checked.gif"
export default function OrderSuccessfull({ open, setOpen, handleCloseModal }) {
     const navigate = useNavigate();

    return (
        <>
            <Modal open={open} onClose={handleCloseModal}>
                <Card className='modal' sx={{paddingBlock:"50px"}}>
                    <Stack spacing={2}>
                        <Stack alignItems='center'>
                            <img width={82} height={82} src={checked} />
                            <Typography variant='h5' className='text-center' fontWeight={600}>Your order has been successfully placed</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' sx={{ my: 2 }} justifyContent='center'>
                        <Button variant='contained' onClick={()=>navigate('/order/material-selection')}>Ok</Button>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}

