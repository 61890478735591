import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const addToCartApi = createAsyncThunk(
    'order/create-cart', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/order/create-cart/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const resetAddToCart = createAction('order/reset-add-to-cart')


const addToCartSlice = createSlice({
    name: 'create-cart',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addToCartApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addToCartApi.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(addToCartApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(resetAddToCart, (state, action) => {
            state.result = []
            state.kinUserData = null
            state.loading = false
            state.error = null
            state.message = ""
        })
    }
});

export const addToCartReducer = addToCartSlice.reducer;
