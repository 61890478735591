import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const getDesignEstimate = createAsyncThunk(
   'layout/DesignEstimate',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`/api/v1/layout/get_all_design_estimate/`, data,{
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            })
            console.log(response.data, "response");
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const designEstimateSlice = createSlice({
    name: 'DesignEstimate',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getDesignEstimate.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getDesignEstimate.fulfilled, (state, action) => {
            state.loading = false
            // state.result = action?.payload?.data
            // state.message = action?.payload?.message
            state.result = action.payload?.result ?? action.payload ?? [];
            state.message = action.payload?.message ?? "";
        })
        builder.addCase(getDesignEstimate.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const designEstimateReducer = designEstimateSlice.reducer