//Date formater for MM/DD/YYYY
export  const convertDateFunction = (date) => {
    const inputDate = new Date(date);
    const formattedDate = `${(inputDate.getMonth() + 1).toString().padStart(2, "0")}/${inputDate.getDate().toString().padStart(2, "0")}/${inputDate.getFullYear()}`;
    return formattedDate;
};


//Date formatter for YYYY-MM-DD
export const convertDateFunctionYMD = (date) => {
    const inputDate = new Date(date);
    const month = inputDate.getMonth() + 1;
    const day = inputDate.getDate();
    const year = inputDate.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    return formattedDate;
};

export const convertToDateFormatYearWithTwoDigits = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
}