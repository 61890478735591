import Body from "../../components/Body";


export default function Create() {
  const rowsData = []

  return (
    <>
      {/* <Appbar />
      <Drawer /> */}
      <Body>
            
      </Body>
    </>
  );
}
