import React from "react";
import { Box, Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import Body from "../../components/Body";
import Filters from "../home/Filters";
import ViewLayout from "../../assets/svg/ViewLayout.svg";
import graycheck from "../../assets/svg/graycheck.svg"
import treegraph from "../../assets/svg/treegraph.svg"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Gfc from "./Gfc";
import FeedbackReceived from "./FeedbackRecived";
import InputDrawing from "./InputDrawing";
import DesignEstimate from "./DesignEstimate";
import SchematicLayout from "./SchematicLayout";
import RevisedLayout from "./RevisedLayout";
import Boxlayout from "./Boxlayout";
import UploadLayout from "./UploadLayout";


function Designplan() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1)
  const [selectedCluster, setSelectedCluster] = useState()
  const [isHide, setIsHide] = useState(false)
  const [uploadLayout, setUploadLayout] = useState(false)
  return <>

    <Body>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>

          <Stack
            direction="row"
            spacing={10}
            sx={{ my: 2 }}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Stack direction="row" alignItems="center">
              <img src={ViewLayout} />
              <Typography
                color={"#05141F"}
                fontSize={"17px"}
                fontWeight={400}
                marginInlineStart={"10px"}
                fontFamily={"'KiaSignatureRegular' !important"}
              >
                View Layout
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card sx={{ borderRadius: "8px" }}>
            <Typography variant="h6" className="fs-17  fw-600">Select Category</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                <Box className="greencard rightseperator p-relative" sx={{ maxWidth: "152px" }}>
                  <Box sx={{ padding: "16px 11px" }}>
                    <Box className="d-flex">
                      <Box sx={{ padding: "4px 6px 6px 0" }}>
                        <img src={graycheck} />
                      </Box>
                      <Box>
                        <Typography variant="h6" className="fs-17  fw-600">My Network</Typography>
                        <img src={treegraph} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="d-flex" sx={{ gap: "12px", paddingLeft: "7.5px", overflow: "auto" }}>
                  <Button className="select-btn active my-auto">
                    New Facility
                  </Button>
                  <Button className="select-btn my-auto">
                    Enhancement
                  </Button>
                  <Button className="select-btn flex-column my-auto">
                    <span>
                      SI 2.0
                    </span>
                    upgrade
                  </Button>
                  <Button className="select-btn flex-column my-auto">
                    <span style={{minWidth:"max-content"}}>
                      {'i2S->3S'}
                    </span>
                    upgrade
                  </Button>
                  <Button className="select-btn my-auto">
                  i2s-ECO
                  </Button>
                  <Button className="select-btn my-auto">
                    EV Transition
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider sx={{ border: "1px solid #CBE6FC !important" }} orientation="vertical" variant="middle" flexItem />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex flex-wrap" sx={{ gap: "15px" }}>
                <Box className="greencard active" onClick={
                  () => {
                    setSelectedCluster(1)
                    setIsHide(true)
                  }
                }
                >

                  <Box className="d-flex">
                    <Box sx={{ padding: "16px 11px" }} className="content d-flex">
                      <Box sx={{ padding: "4px 6px 6px 0" }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0.5C3.86557 0.5 0.5 3.86557 0.5 8C0.5 12.1344 3.86557 15.5 8 15.5C12.1344 15.5 15.5 12.1344 15.5 8C15.5 3.86557 12.1344 0.5 8 0.5ZM8 1.86176C11.3974 1.86176 14.1389 4.60261 14.1389 8C14.1389 11.3974 11.3974 14.1356 8 14.1356C4.6026 14.1356 1.86442 11.3974 1.86442 8C1.86442 4.60261 4.6026 1.86176 8 1.86176ZM11.3981 5.26383C11.2202 5.26859 11.0513 5.34267 10.9273 5.47026L7.31812 9.07942L5.75526 7.51656C5.69243 7.45112 5.61717 7.39887 5.53389 7.36288C5.45062 7.32688 5.361 7.30787 5.27028 7.30695C5.17956 7.30603 5.08957 7.32321 5.00558 7.3575C4.92159 7.39179 4.84528 7.4425 4.78113 7.50665C4.71698 7.5708 4.66628 7.64711 4.63199 7.7311C4.59769 7.81509 4.58051 7.90508 4.58143 7.9958C4.58235 8.08651 4.60137 8.17613 4.63736 8.25941C4.67335 8.34269 4.7256 8.41795 4.79104 8.48078L6.83734 10.5264C6.96523 10.6542 7.13864 10.7261 7.31945 10.7261C7.50027 10.7261 7.67368 10.6542 7.80156 10.5264L11.8908 6.43714C11.9902 6.34168 12.0582 6.21839 12.0861 6.08349C12.114 5.9486 12.1004 5.80842 12.047 5.68142C11.9937 5.55441 11.9031 5.44654 11.7873 5.372C11.6715 5.29746 11.5358 5.25975 11.3981 5.26383Z" fill="#2A3A46" fill-opacity="0.4" />
                        </svg>
                      </Box>
                      <Box>
                        <Typography variant="h6" className="fs-13 fw-400">Amit KIA
                        </Typography>
                        <Typography variant="h6" className="fs-13 fw-400">
                          Noida</Typography>
                        <Typography variant="h6" className=" fw-600">2S</Typography>
                      </Box>
                    </Box>
                    <Box className="side-content d-flex bg-light-blue">
                      <Box className="flex-column m-auto text-center">
                        <Typography className="fs-20">
                          0%
                        </Typography>
                        <Typography className="fs-12 fw-800">
                          Completed
                        </Typography>

                      </Box>
                    </Box>
                  </Box>

                </Box>
                <Box className="greencard">
                  <Box className="d-flex">
                    <Box sx={{ padding: "16px 11px" }} className="content d-flex">
                      <Box sx={{ padding: "4px 6px 6px 0" }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0.5C3.86557 0.5 0.5 3.86557 0.5 8C0.5 12.1344 3.86557 15.5 8 15.5C12.1344 15.5 15.5 12.1344 15.5 8C15.5 3.86557 12.1344 0.5 8 0.5ZM8 1.86176C11.3974 1.86176 14.1389 4.60261 14.1389 8C14.1389 11.3974 11.3974 14.1356 8 14.1356C4.6026 14.1356 1.86442 11.3974 1.86442 8C1.86442 4.60261 4.6026 1.86176 8 1.86176ZM11.3981 5.26383C11.2202 5.26859 11.0513 5.34267 10.9273 5.47026L7.31812 9.07942L5.75526 7.51656C5.69243 7.45112 5.61717 7.39887 5.53389 7.36288C5.45062 7.32688 5.361 7.30787 5.27028 7.30695C5.17956 7.30603 5.08957 7.32321 5.00558 7.3575C4.92159 7.39179 4.84528 7.4425 4.78113 7.50665C4.71698 7.5708 4.66628 7.64711 4.63199 7.7311C4.59769 7.81509 4.58051 7.90508 4.58143 7.9958C4.58235 8.08651 4.60137 8.17613 4.63736 8.25941C4.67335 8.34269 4.7256 8.41795 4.79104 8.48078L6.83734 10.5264C6.96523 10.6542 7.13864 10.7261 7.31945 10.7261C7.50027 10.7261 7.67368 10.6542 7.80156 10.5264L11.8908 6.43714C11.9902 6.34168 12.0582 6.21839 12.0861 6.08349C12.114 5.9486 12.1004 5.80842 12.047 5.68142C11.9937 5.55441 11.9031 5.44654 11.7873 5.372C11.6715 5.29746 11.5358 5.25975 11.3981 5.26383Z" fill="#2A3A46" fill-opacity="0.4" />
                        </svg>
                      </Box>
                      <Box>
                        <Typography variant="h6" className="fs-13 fw-400">Amit KIA
                        </Typography>
                        <Typography variant="h6" className="fs-13 fw-400">
                          Noida</Typography>
                        <Typography variant="h6" className=" fw-600">2S</Typography>
                      </Box>
                    </Box>
                    <Box className="side-content d-flex bg-light-blue">
                      <Box className="flex-column m-auto text-center">
                        <Typography className="fs-20">
                          0%
                        </Typography>
                        <Typography className="fs-12 fw-800">
                          Completed
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="greencard">
                  <Box className="d-flex">
                    <Box sx={{ padding: "16px 11px" }} className="content d-flex">
                      <Box sx={{ padding: "4px 6px 6px 0" }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0.5C3.86557 0.5 0.5 3.86557 0.5 8C0.5 12.1344 3.86557 15.5 8 15.5C12.1344 15.5 15.5 12.1344 15.5 8C15.5 3.86557 12.1344 0.5 8 0.5ZM8 1.86176C11.3974 1.86176 14.1389 4.60261 14.1389 8C14.1389 11.3974 11.3974 14.1356 8 14.1356C4.6026 14.1356 1.86442 11.3974 1.86442 8C1.86442 4.60261 4.6026 1.86176 8 1.86176ZM11.3981 5.26383C11.2202 5.26859 11.0513 5.34267 10.9273 5.47026L7.31812 9.07942L5.75526 7.51656C5.69243 7.45112 5.61717 7.39887 5.53389 7.36288C5.45062 7.32688 5.361 7.30787 5.27028 7.30695C5.17956 7.30603 5.08957 7.32321 5.00558 7.3575C4.92159 7.39179 4.84528 7.4425 4.78113 7.50665C4.71698 7.5708 4.66628 7.64711 4.63199 7.7311C4.59769 7.81509 4.58051 7.90508 4.58143 7.9958C4.58235 8.08651 4.60137 8.17613 4.63736 8.25941C4.67335 8.34269 4.7256 8.41795 4.79104 8.48078L6.83734 10.5264C6.96523 10.6542 7.13864 10.7261 7.31945 10.7261C7.50027 10.7261 7.67368 10.6542 7.80156 10.5264L11.8908 6.43714C11.9902 6.34168 12.0582 6.21839 12.0861 6.08349C12.114 5.9486 12.1004 5.80842 12.047 5.68142C11.9937 5.55441 11.9031 5.44654 11.7873 5.372C11.6715 5.29746 11.5358 5.25975 11.3981 5.26383Z" fill="#2A3A46" fill-opacity="0.4" />
                        </svg>
                      </Box>
                      <Box>
                        <Typography variant="h6" className="fs-13 fw-400">Amit KIA
                        </Typography>
                        <Typography variant="h6" className="fs-13 fw-400">
                          Noida</Typography>
                        <Typography variant="h6" className=" fw-600">2S</Typography>
                      </Box>
                    </Box>
                    <Box className="side-content d-flex bg-light-blue">
                      <Box className="flex-column m-auto text-center">
                        <Typography className="fs-20">
                          0%
                        </Typography>
                        <Typography className="fs-12 fw-800">
                          Completed
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {selectedCluster && isHide && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card sx={{ borderRadius: "8px", minHeight: "270px" }} className="d-flex flex-column">
              <Grid container spacing={2} className="m-auto">
                <Grid item xs={12} sm={12} md={12} lg={12} className=" text-center" >
                  <Typography variant="h5" className="fs-21" sx={{ marginBottom: "25px" }}>
                    You have not uploaded the layout yet
                  </Typography>
                  <Button className="black-btn"
                    onClick={() => {
                      setUploadLayout(true)
                      setIsHide(false)
                    }}
                  //onClick={() => { navigate("/uplaod-layout") }}
                  >
                    Upload Layout
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        {uploadLayout && (isHide === false) && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card sx={{ borderRadius: "8px" }}>
              <Grid container spacing={2}>

                <>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                    {(() => {

                      switch (selectedTab) {
                        case 1:
                          return <InputDrawing />;
                        case 2:
                          return <DesignEstimate />;
                        case 3:
                          return <FeedbackReceived />;
                        case 4:
                          return <SchematicLayout />;
                        case 5:
                          return <RevisedLayout />;
                        case 6:
                          return <UploadLayout />;
                        default:
                          return null;
                      }
                    })()}
                  </Grid>
                </>
                <>
                  {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
              <button onClick={() => setSelectedTab(1)}>Input Drawing</button> 
              <button onClick={() => setSelectedTab(2)}>Design Estimates</button> 
              <button onClick={() => setSelectedTab(3)}>Schematic Layout</button> 
              <button onClick={() => setSelectedTab(4)}>Revised Layout</button>
              <button onClick={() => setSelectedTab(5)}>GFC</button>
              <button onClick={() => setSelectedTab(6)}>GFC Revised Layout</button>
              <button onClick={() => setSelectedTab(7)}>Schematic Layout</button>
            </Grid> */}

                  {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={3} sx={{padding:"0px !important"}}>
                <Boxlayout setSelectedTab={setSelectedTab} selectedTab={selectedTab}/>
            </Grid> */}
                </>
              </Grid>
            </Card>
          </Grid>
        )}


      </Grid>



    </Body>
  </>;
}
export default Designplan