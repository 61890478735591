import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const removeItemApi = createAsyncThunk(
    'cart/removeCartItem',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete('/api/v1/order/cart-item/', {
                data: formData,
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") }
            });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const removeCartItemSlice = createSlice({
    name: 'removeCartItem',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(removeItemApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(removeItemApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(removeItemApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
           
        })
    },
})

export const removeCartItemReducer = removeCartItemSlice.reducer