import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const getMaterial = createAsyncThunk(
    'orders/getMaterial',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/prime_vendor/innmaterial_category/`,{
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") }
            })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const getMaterialSlice = createSlice({
    name: 'getMaterial',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getMaterial.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getMaterial.fulfilled, (state, action) => {
            state.loading = false
            state.result = action.payload?.data || []             
            state.message = action.payload?.message || "Success"
        })
        builder.addCase(getMaterial.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const getMaterialReducer = getMaterialSlice.reducer