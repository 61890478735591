import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const loginApi = createAsyncThunk(
    'auth/login',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`/api/v1/dealor/login`, data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const logoutApi = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            // Implement your logout logic here (if needed)
            // For example, clearing tokens, etc.
            // This is an empty example as it may vary based on your application
            // Assuming logout doesn't need any API call and just resetting state
            return { message: "Logout successful" };
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const loginSlice = createSlice({
    name: 'login',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(loginApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(loginApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(loginApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
        builder.addCase(logoutApi.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(logoutApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = []; // Clear user data upon logout
            state.message = action?.payload?.message;
        });
        builder.addCase(logoutApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action?.payload?.error;
            state.message = action?.payload?.message;
        });
    },
})


export const loginReducer = loginSlice.reducer