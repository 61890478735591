import { combineReducers } from "@reduxjs/toolkit";
import { authOtpViaEmailReducer } from "./auth/authOtpViaEmail";
import { authOtpViaSMSReducer } from "./auth/authOtpViaSMS";
import { currentDealorReducer } from "./auth/currentDealor";
import { dealorShipLocationsReducer } from "./auth/dealorShipLocations";
import { forgetPasswordViaEmailReducer } from "./auth/forgetPasswordViaEmail";
import { forgetPasswordViaSMSReducer } from "./auth/forgetPasswordViaSMS";
import { loginReducer } from "./auth/login";
import { resetPasswordReducer } from "./auth/resetPassword";
import { resetPasswordVerifyOtpReducer } from "./auth/resetPasswordVerifyOtp";
import { updateDealorReducer } from "./auth/updateDealor";
import { verifyAuthOtpReducer } from "./auth/verifyAuthOtp";
import { billInvoicesReducer } from "./orders/billInvoices";
import { dispatchStatusCountReducer } from "./orders/dispatchStatusCount";
import { notificationCountReducer } from "./orders/notificationCount";
import { orderDetailsReducer } from "./orders/orderDetails";
import { ordersReducer } from "./orders/orders";
import { ordersCountReducer } from "./orders/ordersCount";
import { pendingOrdersCountReducer } from "./orders/pendingOrdersCount";
import { placeOrderReducer } from "./orders/placeOrder";
import { uploadInvoiceReducer } from "./orders/uploadInvoice";
import { prodcutCRUDReducer } from "./products/productCRUD";
import { productsReducer } from "./products/products";
import { getCitiesReducer } from "./cities/cities";
import { getUserDetailReducer } from "./userProfile/getUserDetail";
import { editUserDetailReducer } from "./userProfile/editUserDetail";
import { inputDrawingReducer } from "./layout/inputDrawing";
import { addInputDrawingReducer } from "./layout/addInputDrawing";
import { inputDrawingByClusterReducer, resetgetInputDrawingByClusterIdApi } from "./layout/getInputDrawing";
import { outletListReducer } from "./layout/outletList";
import { schematicLayoutReducer } from "./layout/schematicLayout";
import { getFeedbackReducer } from "./layout/getFeedbacks";
import { revisedLayoutViewReducer } from "./layout/postRevisedLayoutView";
import { gfcRevisedReducer } from "./layout/gfcRevised";
import { designEstimateReducer } from "./layout/designEstimate";
import { revisedSchematicReducer } from "./layout/revisedSchematic";
import { revisedGFCViewReducer } from "./layout/postRevisedGFCView";
import { gfcReducer } from "./layout/gfc";
import { getRegionForOutletReducer } from "./orders/getRegionForOutlet";
import { getStateForOutletReducer } from "./orders/getStateForOutlet";
import { getCitiesForOutletReducer } from "./orders/getCitiesForOutlet";
import { getMaterialReducer } from "./orders/materialSelection";
import { productListReducer } from "./orders/productList";
import { addAddressReducer } from "./orders/addAddress";
import { viewOrderReducer } from "./orders/viewOrder";
import { addToCartReducer } from "./orders/addToCart";
import { checkOrderStatusReducer } from "./orders/checkOrderStatus";
import { requireCategoryReducer } from "./orders/getAllCategory";
import { filterCartReducer } from "./orders/filterCart";
import { viewOrderHistoryReducer } from "./viewOrder/viewOrderHistory";
import { viewPaymentReducer } from "./viewOrder/viewPayment";
import { selectOutletReducer } from "./viewOrder/selectOutlet";
import { removeCartItemReducer } from "./orders/removeCartItem";
import { getAllOrderReducer } from "./viewOrder/getAllOrders";
import { getAllOutletReducer } from "./viewOrder/getAllOutlet";
import { drawerTypeReducer } from "./drawer";

const rootReducer = combineReducers({
    // auth
    login: loginReducer,
    currentDealor: currentDealorReducer,
    dealorShipLocations: dealorShipLocationsReducer,
    authOtpViaEmail: authOtpViaEmailReducer,
    authOtpViaSMS: authOtpViaSMSReducer,
    verifyAuthOtp: verifyAuthOtpReducer,
    forgetPasswordViaEmail: forgetPasswordViaEmailReducer,
    forgetPasswordViaSMS: forgetPasswordViaSMSReducer,
    resetPasswordVerifyOtp: resetPasswordVerifyOtpReducer,
    resetPassword: resetPasswordReducer,
    updateDealor: updateDealorReducer,
    // orders
    orders: ordersReducer,
    orderDetails: orderDetailsReducer,
    billInvoices: billInvoicesReducer,
    placeOrder: placeOrderReducer,
    dispatchStatusCount: dispatchStatusCountReducer,
    notificationCount: notificationCountReducer,
    ordersCount: ordersCountReducer,
    pendingOrdersCount: pendingOrdersCountReducer,
    uploadInvoice: uploadInvoiceReducer,
    // products
    products: productsReducer,
    prodcutCRUD:prodcutCRUDReducer,
    schematicLayoutReducer:schematicLayoutReducer,

  
    
    getCitiesReducer:getCitiesReducer,


  


    //User Detail
    getUserDetailReducer:getUserDetailReducer,
    editUserDetailReducer:editUserDetailReducer,


    //layout
    inputDrawingReducer:inputDrawingReducer,
    addInputDrawingReducer:addInputDrawingReducer,
    inputDrawingByClusterReducer:inputDrawingByClusterReducer,
    outletListReducer:outletListReducer,
    schematicLayoutReducer:schematicLayoutReducer,
    getFeedbackReducer:getFeedbackReducer,
    revisedLayoutViewReducer:revisedLayoutViewReducer,
    gfcRevisedReducer:gfcRevisedReducer,
    designEstimateReducer:designEstimateReducer,
    revisedSchematicReducer:revisedSchematicReducer,
    revisedGFCViewReducer:revisedGFCViewReducer,
    gfcReducer:gfcReducer,

    getRegionForOutletReducer:getRegionForOutletReducer,
    getStateForOutletReducer:getStateForOutletReducer,
    getCitiesForOutletReducer:getCitiesForOutletReducer,

    getMaterialReducer:getMaterialReducer,
    productListReducer:productListReducer,

    addAddressReducer:addAddressReducer,
    viewOrderReducer:viewOrderReducer,
    addToCartReducer:addToCartReducer,
    getMaterialReducer:getMaterialReducer,
    checkOrderStatusReducer:checkOrderStatusReducer,
    requireCategoryReducer:requireCategoryReducer,
    filterCartReducer:filterCartReducer,
    viewOrderHistoryReducer:viewOrderHistoryReducer,
    viewPaymentReducer:viewPaymentReducer,
    selectOutletReducer:selectOutletReducer,
    removeCartItemReducer:removeCartItemReducer,

    getAllOrderReducer:getAllOrderReducer,
    getAllOutletReducer:getAllOutletReducer,
    drawerTypeReducer:drawerTypeReducer
   

})

export default rootReducer