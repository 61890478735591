import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";

export const getInputDrawingByClusterIdApi = createAsyncThunk(
  'layout/input-drawing-by-cluster',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(
        '/api/v1/layout/get_input_drawing/',
        data,
        {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
          },
        }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const resetInputDrawingByClusterState = createAction('layout/reset-input-drawing-by-cluster')


const inputDrawingByClusterSlice = createSlice({
  name: 'input-drawing-by-cluster',
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(getInputDrawingByClusterIdApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInputDrawingByClusterIdApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.data;
      state.message = action?.payload?.message;
    });
    builder.addCase(getInputDrawingByClusterIdApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
    builder.addCase(resetInputDrawingByClusterState, (state, action) => {
        state.result = []
        state.loading = false
        state.error = null
        state.message = ""
    })
   
  },
});

export const inputDrawingByClusterReducer = inputDrawingByClusterSlice.reducer;
