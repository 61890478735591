import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Thunk to handle setting the drawer state
export const isHandleDrawer = createAsyncThunk(
    'drawer/handleDrawer',
    async (isOpen, { rejectWithValue }) => {
        try {
            // Here you can implement any logic needed before setting the state
            // For example, you might have some API call or async operation
            // but in this case, we're just directly passing the state value.
            return isOpen;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const drawerTypeSlice = createSlice({
    name: 'handleDrawer',
    initialState: { result: false, loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder
            .addCase(isHandleDrawer.pending, (state) => {
                state.loading = true;
            })
            .addCase(isHandleDrawer.fulfilled, (state, action) => {
                state.loading = false;
                state.result = action.payload;
                state.message = action.payload ? "Drawer is open" : "Drawer is closed";
            })
            .addCase(isHandleDrawer.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.message = "Failed to handle drawer state";
            });
    },
});

export const drawerTypeReducer = drawerTypeSlice.reducer;