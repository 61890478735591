import ConsentMessage from "../reusable/ConsentMessage"
import { schematicLayout } from "../../store/layout/schematicLayout";
import React, { useEffect, useState } from "react";
import { convertToFormData } from "../ConvertToFormData";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { handleDownload } from "../reusable/downloadFile";
import RequestForRevision from "../reusable/RequestForRevision";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import Loader from "../reusable/Loader";
import DoneIcon from '@mui/icons-material/Done';
export default function SchematicLayout() {
    const [open, setOpen] = useState(false)
    const [openConsent, setOpenConsent] = useState(false)
    const [data, setData] = useState([]);
   

    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    // const {loading : loading,  result: schematicData} = useSelector((state) => state?.schematicLayoutReducer);
    // console.log(schematicData, "schematicData")
    const {result : revisedSchematicLayoutData, loading : revisedShematicLayoutLoading} = useSelector((state) => state?.revisedLayoutViewReducer);
    const {result : schematicLayoutData, loading : schematicLayoutLoading} = useSelector((state) => state?.schematicLayoutReducer);
    console.log(schematicLayoutData,"schematicLayoutData")
    localStorage.removeItem("isRevisedViewClick");

    const dispatch = useDispatch();
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleConsentOpen = () => {
        setOpenConsent(true)
    }

    const handleConsentClose = () => {
        setOpenConsent(false)
    }

  
    const getInputDrawing = async () => {
        const formD = {
            'form_id': formId?.id
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(schematicLayout(data));
            console.log(response, "response");
            // setData(response?.payload?.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getInputDrawing();
    }, []);

    // if (loading) {
    //     return <Loader/>;
    // }

        useEffect(() => {
        const isRevisedViewClick = localStorage.getItem("isRevisedViewClick");
        setData(isRevisedViewClick === '1' ? revisedSchematicLayoutData : schematicLayoutData);
      }, [revisedSchematicLayoutData, schematicLayoutData]);
      console.log(data, "data");

    return (

        //if approve_by_admin=== true then i have to show data<>
        <>
        {data?.approve_by_admin ==true ?(
            <>
            <Box className="h-100 d-flex flex-column justify-content-between">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Typography variant="h6" className="fs-17  fw-400">Schematic Layout</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                            <Box sx={{ width: "213px" }}>
                                {data?.schematic_pdf?.map((schematicLayoutData) => (
                                    <>
                                        <Box className="upload-box bg-white text-center">
                                            <Box className="m-auto p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                    <img width={40} height={40} src={pdf} className="my-auto" />
                                                    <Typography>
                                                        {'PDF File'}
                                                    </Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`https://kiastageapi.dev.vinove.com${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </>
                                ))}
                            </Box>
                            <Box sx={{ width: "213px" }}>
                                {data?.schematic_cad?.map((schematicLayoutData) => (
                                    <>
                                        <Box className="upload-box bg-white text-center">
                                            <Box className="m-auto p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                    <img width={40} height={40} src={cad} className="my-auto" />
                                                    <Typography>
                                                        {'CAD File'}
                                                    </Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`https://kiastageapi.dev.vinove.com${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>

                                    </>
                                ))}
                            </Box>
                            <Box className="w-100" sx={{ marginTop: '40px' }}>
                                <Box className="d-flex" sx={{ gap: '18px' }}>
                                    <Typography variant="h6" className="fs-17 my-auto">Attached Design Estimates</Typography>
                                </Box>
                                <Box sx={{ height: "fit-content" }}>
                                    <Tooltip title={data?.design_estimate?.estimate} placement="top" className={` uploadedfilename`} >
                                        <Typography>
                                            {'Design Estimate'}
                                            {data?.estimate}
                                        </Typography>
                                        <FullscreenIcon onClick={() => handleDownload(new Date() + 'schematic_designEstimate', data?.design_estimate?.estimate)} />
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box className="w-100" sx={{ marginTop: '5px' }}>
                                <Box className="d-flex" sx={{ gap: '18px' }}>
                                    <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment </Typography>
                                </Box>
                                <Box sx={{ height: "fit-content" }}>
                                    <TextareaAutosize
                                        disabled
                                        value={data?.comment}
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        name="comment"
                                        style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }}
                                        className="bg-white"
                                        minRows={6} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "23px" }}>
  
                            {data?.approve_by_dealor === false ? 
                            <Button className="black-btn"   onClick={() => handleOpen()}>Approve</Button>
                           
                        :    <Button className="black-btn" style={{color: "green"}}><DoneIcon style={{ marginRight: "2px", marginTop: "-5px" }}/> Approved</Button>
                            
                        }
                        <Button className="white-btn" onClick={() => handleConsentOpen()}>Request for revision</Button>
                    </Grid>
                </Grid>
            </Box>
            <ConsentMessage open={open} handleClose={handleClose} schematicData={schematicLayoutData} />
            <RequestForRevision open={openConsent} handleClose={handleConsentClose} id={formId.id} />
        </>
        ):(<div className="d-flex h-100 "><p className="m-auto">No data found or It is not approved</p></div>)}
        </>
     
    );
}