import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const postAddressApi = createAsyncThunk(
    'order/add-address', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/order/address/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const addAddressSlice = createSlice({
    name: 'add-address',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postAddressApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(postAddressApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload;
            state.error = null;
        });
        builder.addCase(postAddressApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const addAddressReducer = addAddressSlice.reducer;
