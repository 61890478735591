

import {createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const filterCartApi = createAsyncThunk(
    'order/filterCart', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/order/filter-cart/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            if(response.data.length === 0) {
                return rejectWithValue("Cart is empty");
            }
            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const resetCartByClusterState = createAction('layout/reset-input-drawing-by-cluster')

const filterCartSlice = createSlice({
    name: 'filterCart',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(filterCartApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(filterCartApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(filterCartApi.rejected, (state, action) => {
            state.loading = false
            state.result = []
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
        builder.addCase(resetCartByClusterState, (state, action) => {
            state.result = []
            state.loading = false
            state.error = null
            state.message = ""
        })
 
    },
})

export const filterCartReducer = filterCartSlice.reducer