import DateRangeIcon from '@mui/icons-material/DateRange'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InventoryIcon from '@mui/icons-material/Inventory'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SearchIcon from '@mui/icons-material/Search'
import SortIcon from '@mui/icons-material/Sort'
import TuneIcon from '@mui/icons-material/Tune'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, InputAdornment, List, ListItemButton, ListItemText, Menu, MenuItem, Paper, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Body from '../../components/Body'
import { ordersApi } from '../../store/orders/orders'
import OrderDetails from './OrderDetails'
import NodataImg from "../../assets/img/noData.png"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';

export default function OrderStatus({ first_name }) {
    const ordersSelector = useSelector(state => state?.orders)
    const { result, loading } = ordersSelector

    const dispatch = useDispatch()

    const [orderDetailsModel, setOrderDetailsModel] = useState(false)
    const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
    const statusMenuOpen = Boolean(statusAnchorEl);
    const [filterDrawer, setFilterDrawer] = useState(false)

    const columns = [
        {
            field: 'order_id',
            headerName: 'Order ID',
            width: 150,
            renderCell: (params) => (
                <Button onClick={handleOrderDetails} sx={{ color: '#2073B0' }}>{params?.row?.order_id}</Button>
            )
        },
        {
            field: 'user',
            headerName: 'Supplier',
            width: 150
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 150
        },
        {
            field: 'created_at',
            headerName: 'Order Date',
            width: 150
        },
        {
            field: 'eta',
            headerName: 'ETA',
            width: 150
        },
        {
            field: 'price',
            headerName: 'Price',
            width: 150
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150
        },
        {
            field: 'invoice_date',
            headerName: 'Invoice Date',
            minWidth: 300,
        },
        {
            field: 'uload_payment_recipt',
            headerName: 'Upload Payment Reciept',
            minWidth: 300,
            renderCell: (params) => (
                <>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="outlined" component="span" color='info' size='small' startIcon={<UploadFileIcon />}>Upload Payment Reciept</Button>
                    </label>
                </>
            )
        },
        {
            field: 'donwload_invoice',
            headerName: 'View & Download Invoice',
            minWidth: 300,
            renderCell: (params) => (
                <Button color='info' size='small' startIcon={<DownloadIcon />} variant='outlined'>Download</Button>
            )
        },
    ]

    const handleOrderDetails = () => {
        setOrderDetailsModel(true)
    }

    const handleFilterStatusMenu = (event) => {
        setStatusAnchorEl(event.currentTarget);
    }

    const handleStatusMenuClose = () => {
        setStatusAnchorEl(false)
    }

    const handleToggleOrderFilter = () => {
        setFilterDrawer(true)
    };

    const handleCloseOrderFilter = () => {
        setFilterDrawer(false)
    }

    const handleSearchOrders = (evt) => {
        dispatch(ordersApi())
    }

    useEffect(() => {
        dispatch(ordersApi())
    }, [dispatch])


    return (
        <>
            {/* <Appbar />
            <Drawer /> */}
            <Body>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Box className='divider1'></Box>
                    <Typography variant='h6'>{`Welcome ${first_name}`}</Typography>
                </Stack>
                <Grid container alignItems='center'>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                            <InventoryIcon fontSize='large' />
                            <Typography>Order Status</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Stack direction='row' spacing={2}>
                            <TextField fullWidth size='medium' InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>) }} placeholder='Search by Order ID , Product Name' onChange={handleSearchOrders} />
                            <Button sx={{ bgcolor: '#fff' }} variant='outlined' onClick={handleFilterStatusMenu} endIcon={<KeyboardArrowDownIcon />}>All</Button>
                            <Button sx={{ bgcolor: '#fff' }} variant='outlined' onClick={handleToggleOrderFilter} startIcon={<TuneIcon />}>Filter</Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Paper elevation={0}>
                    <DataGrid
                        sx={{ maxHeight: 500 }}
                        getRowId={(row) => row.id}
                        rows={result}
                        columns={columns}
                        loading={loading}
                        disableRowSelectionOnClick
                        slots={{
                            noRowsOverlay: () => <Box className='noData'><img src={NodataImg} alt='no data found image' /></Box>,
                        }}
                        pagination
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </Paper>
            </Body>

            {/* status menu */}
            <Menu anchorEl={statusAnchorEl} open={statusMenuOpen} onClose={handleStatusMenuClose}>
                <MenuItem onClick={handleStatusMenuClose}>Completed</MenuItem>
                <MenuItem onClick={handleStatusMenuClose}>In Process</MenuItem>
                <MenuItem onClick={handleStatusMenuClose}>Cancelled</MenuItem>
            </Menu>

            {/* order filters */}
            <SwipeableDrawer anchor={'right'} open={filterDrawer} onClose={handleCloseOrderFilter} onOpen={handleToggleOrderFilter}>
                <Stack sx={{ p: 2 }} spacing={3}>
                    <Stack spacing={1}>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <SortIcon />
                            <Typography>Sort by</Typography>
                        </Stack>
                        <Accordion className='accordion1'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Accordion 1</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List component="nav">
                                    <ListItemButton><ListItemText primary="First Uploded" /></ListItemButton>
                                    <ListItemButton><ListItemText primary="A to Z" /></ListItemButton>
                                    <ListItemButton><ListItemText primary="Z to A" /></ListItemButton>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>

                    <Stack spacing={1}>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <DateRangeIcon />
                            <Typography>Custom Date</Typography>
                        </Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    <Typography sx={{ width: '30%' }}>Start Date</Typography>
                                    <DatePicker slotProps={{ textField: { size: 'small' } }} />
                                </Stack>
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    <Typography sx={{ width: '30%' }}>End Date</Typography>
                                    <DatePicker slotProps={{ textField: { size: 'small' } }} />
                                </Stack>
                            </Stack>
                        </LocalizationProvider>
                    </Stack>

                    <Stack direction='row' spacing={1} sx={{ mt: 2 }} justifyContent='center'>
                        <Button onClick={handleCloseOrderFilter}>Cancel</Button>
                        <Button variant='contained'>Apply</Button>
                    </Stack>
                </Stack>
            </SwipeableDrawer>


            <OrderDetails open={orderDetailsModel} setOpen={setOrderDetailsModel} />
        </>
    )
}
