// export const convertToFormData=(payload)=>{
//     const form_data = new FormData();
//     let keys=Object.keys(payload);
//     keys.forEach(element => {
//         if(payload[element]!=null&&payload[element]!=undefined)
//         form_data.append(element,payload[element])
//     });
//     return form_data;
// }

export const convertToFormData = (payload) => {
    const form_data = new FormData();
    let keys = Object.keys(payload);
    keys.forEach((element) => {
        if (payload[element] != null && payload[element] !== undefined) {
            if (payload[element] instanceof FileList) {
                for (let i = 0; i < payload[element].length; i++) {
                    form_data.append(element, payload[element][i]);
                }
            } else if(Array.isArray(payload[element])){              
                    form_data.append(element, JSON.stringify(payload[element]));          
            }else {
                form_data.append(element, payload[element]);
            }
        }
    });
    return form_data;
};

export const formatDateString = (dateString) => {
    const date = new Date(dateString);
    
    const options = { 
        day: 'numeric', 
        month: 'long', 
        year: 'numeric' 
    };

    // Creating a custom formatter for day suffix (st, nd, rd, th)
    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const day = date.getDate();
    const dayWithSuffix = `${day}${daySuffix(day)}`;

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formattedDate.replace(date.getDate(), dayWithSuffix);
}