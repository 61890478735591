import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const viewNotificationApi = createAsyncThunk(
    'orders/viewNotification',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/order/viewednotification/', formData,{
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }  
)

const viewNotificationSlice = createSlice({
    name: 'viewNotification',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
    
        builder.addCase(viewNotificationApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(viewNotificationApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(viewNotificationApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });
    },
})

export const viewNotificationReducer = viewNotificationSlice.reducer