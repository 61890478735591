import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getDesignEstimate } from '../../store/layout/designEstimate';
import { convertToFormData } from '../ConvertToFormData';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import RequestForInvoice from "../reusable/RequestForInvoice";
// import DialogModal from '../reusable/DialogModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function DesignEstimate() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([]);
  // console.log(data, "data");
  // const formId = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data[0]);
  const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
  console.log(formId, "formId");
  const handleOpen = () => {
    setOpen(true)
}
const handleClose = () => {
    setOpen(false)
}

  const designEstimate = async () => {
    const formD = {
      'form_id': formId.id
    }
    const data = convertToFormData(formD);
    try {
      const response = await dispatch(getDesignEstimate(data));
      console.log(response, "response");
      setData(response?.payload?.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    designEstimate();
  }, []);
  
  return (
    <>
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <Typography variant="h6" className="fs-17  fw-400">Design Estimate</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
          <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ background: "#85949F !important" }}>Estimate Type</StyledTableCell>
                  <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Date</StyledTableCell>
                  <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment Status</StyledTableCell>
                  <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Invoice</StyledTableCell>
                  <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => (
                  <StyledTableRow key={row.estimate}>
                    <StyledTableCell component="th" scope="row">
                   {row?.estimate_type}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.created_at ? convertToDateFormatYearWithTwoDigits(row.created_at) : "-"}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.payment_status === 1 ? (
                        <Button className="doneBtn">Done</Button>
                      ) : (
                        <Button className="notdoneBtn">Not Done</Button>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">    {row.invoice !== 1 ? (
                      <Button className="notdoneBtn" onClick={() => handleOpen()}>Upload</Button>
                    ) : (
                      <Button className="DownloadBtn">View</Button>
                    )}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button className="DownloadBtn">Download</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
    <RequestForInvoice open={open} handleClose={handleClose}/>
    {/* <DialogModal/> */}
    </>
  );
}