import { Phone } from "@mui/icons-material";
import * as yup from "yup";


export const loginSchema = yup.object({
  email: yup.string().email("Invalid email address").required("Email is Required"),
  password: yup.string().matches().required("Password is Required"),
  
})
.required();


export const addApplicantSchema = yup.object({
  first_name: yup.string().required("Please provide First Name"),
  last_name: yup.string().required("Please provide Last Name"),
  email: yup.string().email("Invalid email address").required("Please provide Email"),
  phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
  address: yup.string().required("Please provide Address"),
  city: yup.string().required("Please provide City"),
  pincode: yup.string().required("Please provide Pincode"),
  zone: yup.string().required("Please provide Zone"),
  state: yup.string().required("Please provide State"),
})
.required();

export const addKinUserSchema = yup.object({
    first_name: yup.string().required("Please provide First Name"),
    last_name: yup.string().required("Please provide Last Name"),
    email: yup.string().email("Invalid email address").required("Please provide Email"),
    password: yup.string().required("Please provide a Password").min(8, "Password must be at least 8 characters long"),
    phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
    address: yup.string().required("Please provide Address"),
    city: yup.string().required("Please provide City"),
    pin_code: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
    zone: yup.string().required("Please provide Zone"),
    state: yup.string().required("Please provide State"),
    role: yup.string().required("Please provide Role"), 
  }).required();

  export const addInnoceansUserSchema = yup.object({
    first_name: yup.string().required("Please provide First Name"),
    last_name: yup.string().required("Please provide Last Name"),
    email: yup.string().email("Invalid email address").required("Please provide Email"),
    password: yup.string().required("Please provide a Password").min(8, "Password must be at least 8 characters long"),
    // phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
    address: yup.string().required("Please provide Address"),
    city: yup.string().required("Please provide City"),
    pincode: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
    zone: yup.string().required("Please provide Zone"),
    state: yup.string().required("Please provide State"),
    role: yup.string().required("Please provide Role"), 
  }).required();

  export const addGroupSchema = yup.object({
    dealer_group_name: yup.string().required("Please provide Dealer Group Name"),
    salutation: yup.string().required("Please provide salutation"),
    dob: yup.string().required("Please provide Date of Birth"),
    dp_name: yup.string().required("Please provide DP Name"),
    dealer_priciple_email_address: yup.string().email("Invalid email address").required("Please provide Email"),
    dealer_principle_mobile_number: yup.string().matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits").required("Please provide Phone Number"),
    correspondence_address: yup.string().required("Please provide Address"),
    pin_code: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
    partner_type: yup.string().required("Please provide Partner"),
    main_city: yup.string().required("Please provide Main City"),
    business_background: yup.array()
        .of(yup.string()) // Define the type of array elements
        .min(1, "Please select at least one option for Business Background")
        .required("Please select at least one option for Business Background"),
    brands: yup.array()
        .of(yup.string()) // Define the type of array elements
        .min(1, "Please select at least one option for Brand")
        .required("Please select at least one option for Brand"),  
  }).required(); 



  export const addGroupOutletSchema = yup.object({
    dealer_group: yup.string().required("Please provide Dealer Group"),
    region: yup.string().required("Please provide Region"),
    state: yup.string().required("Please provide State"),
    city: yup.string().required("Please provide City"),
    cluster: yup.string().required("Please provide Cluster"),
    location: yup.string().required("Please provide Location"),
    tier: yup.string().required("Please provide Tier"),
    outlet_type: yup.string().required("Please provide Outlet Type"),
    facility_type: yup.string().required("Please provide Facility Type"),
    project_type_showroom: yup.string().required("Please provide Project Type (Showroom)"),
    project_type_workshop: yup.string().required("Please provide Project Type (Workshop)"),
    ownership_showroom: yup.string().required("Please provide Ownership (Showroom)"),
    ownership_workshop: yup.string().required("Please provide Ownership (Workshop)"),
    // lease_expiry_date_showroom : yup.string().required("Please provide Lease Expiry Date (Showroom)"),
    // lease_expiry_date_workshop : yup.string().required("Please provide Lease Expiry Date (Workshop)"),
    showroom_address: yup.string().required("Please provide Showroom Address"),
    showroom_pincode: yup.string().required("Please provide Showroom Pincode"),
    showroom_longitude: yup.string().required("Please provide Showroom Longitude"),
    showroom_latitude: yup.string().required("Please provide Showroom Latitute"),
    workshop_address: yup.string().required("Please provide Workshop Address"),
    workshop_pincode: yup.string().required("Please provide Workshop Pincode"),
    workshop_longitude: yup.string().required("Please provide Workshop Longitude"),
    workshop_latitude: yup.string().required("Please provide Workshop Latitude"),
    status: yup.string().required("Please provide Status"),
    appointment_date : yup.string().required("Please provide Appointment Date"),
    start_of_operation_target_date_showroom : yup.string().required("Please provide Date of Start of Operation Showroom"),
    start_of_operation_target_date_workshop : yup.string().required("Please provide Date of Start of Operation Workshop"),
    // closure_date_showroom : yup.string().required("Please provide Closure Date showroom"),
    // closure_date_workshop : yup.string().required("Please provide Closure Date workshop"),
    facility_category: yup.array().of(yup.string().required()).required("Please provide Facility Category") 
  }).required(); 

  export const addSupplierUserSchema = yup.object({
    first_name: yup.string().required("Please provide First Name"),
    last_name: yup.string().required("Please provide Last Name"),
    email: yup.string().email("Invalid email address").required("Please provide Email"),
    password: yup.string().required("Please provide a Password").min(8, "Password must be at least 8 characters long"),
    phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
    address: yup.string().required("Please provide Address"),
    city: yup.string().required("Please provide City"),
    // pincode: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
    zone: yup.string().required("Please provide Zone"),
    state: yup.string().required("Please provide State"),
    role: yup.string().required("Please provide Role"), 
  }).required();

  export const addUserRoleSchema = yup.object({
    usertype_name: yup.string().required("Please provide User Type"),
    name: yup.string().required("Please provide Role"), 
  }).required();

  //Layout
  // export const layoutDrawingSchema = yup.object({
  //   beam_height: yup.string().required("Please provide Beam Height"),
  //   ceiling_height: yup.string().required("Please provide Ceiling Height"), 
  //   building_height: yup.string().required("Please provide Building Total Height"), 
  //   distance_from_road: yup.string().required("Please provide Road to Start of Building"),
  //   comment: yup.string().required("Please provide Comments"),
  //   cadFile: yup.mixed().required("CAD file is required"),
  //   pdfFile: yup.mixed().required("PDF file is required")
    
  // }).required();

  export const layoutDrawingSchema = yup.object({
    beam_height: yup.string().required("Please provide Beam Height"),
    ceiling_height: yup.string().required("Please provide Ceiling Height"),
    building_height: yup.string().required("Please provide Building Total Height"),
    distance_from_road: yup.string().required("Please provide Road to Start of Building"),
    //comment: yup.string().required("Please provide Comments"),
    cadFile: yup.mixed().test('cadFile', "CAD file is required", function (value) {
      return value || this.options.context?.inputDrawing?.input_cad?.length > 0;
    }),
    pdfFile: yup.mixed().test('pdfFile', "PDF file is required", function (value) {
      return value || this.options.context?.inputDrawing?.input_pdf?.length > 0;
    }),
    interImage: yup.mixed().test('interior_images', "Please upload an interior image", function (value) {
      return value || this.options.context?.inputDrawing?.layout_interior?.length > 0 || this.options.context?.interiorFileUrl?.length > 0;
  }),
  exteriorImage: yup.mixed().test('exterior_images', "Please upload an exterior image", function (value) {
      return value || this.options.context?.inputDrawing?.layout_exterior?.length > 0 || this.options.context?.exteriorFileUrl?.length > 0;
  }),

  }).required();


  

  export const cadUploadSchema = yup.object({
    cadFile: yup.mixed()
      .required("CAD file is required")
      .test("fileType", "Only DWG files are allowed", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].type !== "application/dwg" && !value[i].name.endsWith('.dwg')) {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].size > 10 * 1024 * 1024) {
              return false;
            }
          }
          return true;
        }
        return false;
      }),
  }).required();

  export const pdfUploadSchema = yup.object({
    pdfFile: yup.mixed()
        .required("PDF file is required")
        .test("fileType", "Only PDF files are allowed", (value) => {
            // Check if value is an array and contains files
            if (value && value.length > 0) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i] && value[i].type !== "application/pdf") {
                        return false;
                    }
                }
                return true;
            }
            return false;
        })
        .test("fileSize", "Each file should be less than 10MB", (value) => {
            // Check if value is an array and contains files
            if (value && value.length > 0) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i] && value[i].size > 10 * 1024 * 1024) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        }),
  }).required();

  export const billingAddressSchema = yup.object({
    name: yup.string().required("Please provide name"),
    city_id: yup.string().required("Please provide city"), 
    pincode: yup.string().required("Please provide pincode"), 
    address: yup.string().required("Please provide address"),
    zone_id: yup.string().required("Please provide Zone"),
    state_id: yup.string().required("Please provide State"),   
    phone: yup.string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("Please provide Phone"),  
  }).required();


