


import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import Body from "../../components/Body";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { productListApi } from '../../store/orders/productList';
import { useDispatch, useSelector } from 'react-redux';
import deletesvg from "../../assets/svg/delete.svg";
import "../../styles/custom.table.scss";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import RequiredMaterial from '../reusable/RequiredMaterial';
import { updateCartApi } from '../../store/orders/updateCart';
import { showToast } from '../../toast/toast';
import { filterCartApi } from '../../store/orders/filterCart';
import boxsvg from '../../assets/svg/box.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { removeItemApi } from '../../store/orders/removeCartItem';
import DeleteConfirmationModal from '../reusable/DeleteConfirmationModal';
import { diapatchOrder } from '../../store/orders/dispatchOrder';
import OrderSuccessfull from '../reusable/OrderSuccessfull';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
function CheckCart() {
    const dispatch = useDispatch();
  
    // ----------------quantity input----------------
    const blue = {
        100: '#daecff',
        200: '#b6daff',
        300: '#66b2ff',
        400: '#3399ff',
        500: '#007fff',
        600: '#0072e5',
        700: '#0059B2',
        800: '#004c99',
    };
    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };
    const StyledInputRoot = styled('div')(
        ({ theme }) => `
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 400;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        border: 0.8px solid rgba(98, 118, 133, 0.43);
        padding:5px;
        border-radius: 4px;
      `,
    );
    const StyledButton = styled('button')(
        ({ theme }) => `
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        line-height: 1.5;
        border: 1px solid;
        border-radius: 3px;
        border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
        color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
        width: 16px;
        height: 18px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;
      
        &:hover {
          cursor: pointer;
          background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
          border-color: ${theme.palette.mode === 'dark' ? blue[500] : blue[400]};
          color: ${grey[50]};
        }
      
        &:focus-visible {
          outline: 0;
        }
      
        &.increment {
          order: 1;
        }
      `,
    );
    const StyledInput = styled('input')(
        ({ theme }) => `
        font-size: 0.875rem;
        font-family: inherit;
        font-weight: 400;
        line-height: 1.375;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: #ffffff !important;
        border:none !important;
        box-shadow: none !important;
        border-radius: 8px;
        margin: 0 8px;
        padding: 0px 12px;
        outline: 0;
        min-width: 0;
        width: 2.5rem;
        text-align: center;
      
        &:hover {
          border-color: ${blue[400]};
        }
      
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
        }
      
        &:focus-visible {
          outline: 0;
        }
      `,
    );
    // ----------------quantity input end----------------


    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [purpose, setPurpose] = useState("");
    const [loading, setLoading] = useState(true);
    const [quantityLoading, setQuantityLoading] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const data = useSelector((state) => state?.filterCartReducer?.result?.items);
    const cart_id = useSelector((state) => state?.addToCartReducer?.kinUserData?.cart_id);
    const CartId = useSelector((state) => state?.checkOrderStatusReducer?.result?.cart?.id);
    const { order_exists: checkOrderStatus, cart: cart } = useSelector((state) => state?.checkOrderStatusReducer?.result);
    const navigate = useNavigate();
    const location = useLocation();
    const { itemId } = location.state || {};
    useEffect(() => {
        if (data) {
            setCartItems(data);
        }else {
            setCartItems([]);
        }
    },[data])

    const handlePurposeChange = (event) => {
        setPurpose(event.target.value);
    };

    const deleteUser = async (id) => {
        setOpenModal(true);
        // setDeleteId(id)
    };

    useEffect(() => {
        if (itemId) {
            dispatch(productListApi({ categoryId: itemId }));
        }
    }, [dispatch, itemId]);


    useEffect(() => {
        dispatch(filterCartApi({ cart_id: cart_id ? cart_id : CartId }));

    }, [dispatch]);

    useEffect(() => {
        calculateTotals(cartItems);
        setLoading(false);
    }, [quantities, cartItems]);

    const calculateTotals = (temp) => {
        let newSubtotal = 0;
        temp?.forEach((item) => {
            const quantity = item.quantity
            newSubtotal += item.vendor_price * quantity;
        });
        newSubtotal = (Math.floor(newSubtotal * 100) / 100).toFixed(2);
        setSubtotal(newSubtotal);
        setTotal(newSubtotal);
    };

    const handleIncrement = (itemId, itemQuanity) => {
       if(!loading) {
        const temp = cartItems.map((item) => {
            if (item.id === itemId) {
                return { ...item, quantity: itemQuanity + 1 };
            }
            return item;
        });
        setCartItems(temp);
        updateQuantity(temp);
       } 
        
        
    };

    // const handleDecrement = (itemId, itemQuanity) => {
    //     if(!loading) {
    //         const temp = cartItems.map((item) => {
    //             if (item.id === itemId) {
    //                 return { ...item, quantity: itemQuanity - 1 };
    //             }
    //             return item;
    //         });
    //         setCartItems(temp);
    //         updateQuantity(temp); 
    //     }
   
    // };

    const handleDecrement = (itemId, itemQuantity) => {
        if (!loading && itemQuantity > 1) {
            const temp = cartItems.map((item) => {
                if (item.id === itemId) {
                    return { ...item, quantity: itemQuantity - 1 };
                }
                return item;
            });
            setCartItems(temp);
            updateQuantity(temp); 
        }
    };
    

    const debouncedUpdateQuantity = debounce((itemId, newQuantity) => {
        updateQuantity(itemId, newQuantity);
    }, 1000);

    const handleChange = (itemId, value) => {
        if(!loading) {
        const newValue = Math.max(Number(value), 1);
        const temp = cartItems.map((item) => {
            if (item.id === itemId) {
                return { ...item, quantity: newValue };
            }
            return item;
        });
        setCartItems(temp);
       setCurrentData(itemId);
    //    calculateTotals(temp);
        }

    };
//  const handleUpdate =()=>{
//     console.log(cartItems);
//     const quantity = cartItems.filter(item => item.id === currentData)[0].quantity;
//     console.log(quantity);
//     const newValue = Math.max(Number(quantity), 0);
//     updateQuantity(cartItems);
//  }

const handleUpdate = () => {
    const quantity = cartItems.find(item => item.id === currentData)?.quantity || 1;
    const newValue = Math.max(Number(quantity), 1); // Ensure the value is at least 1
    updateQuantity(cartItems);
};

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateQuantity = async (temp) => {

        // const updatedItems = data.map(item =>
        //     item.id === itemId ? { ...item, quantity: newQuantity } : item
        // );
        const items = temp.map(item => ({
            id: item.id,
            product: item.product,
            quantity: item.quantity
        }));
        const requestData = {
            id: cart_id ? cart_id : CartId,
            items: items
        };

        try {
            setQuantityLoading(true);
            await dispatch(updateCartApi(requestData));
            setTimeout(() => {
                dispatch(filterCartApi({ cart_id: cart_id ? cart_id : CartId }));
            }, 2000)
            setQuantityLoading(false);
            showToast("Item added successfully");
            //navigate("/order/place-Order");
        } catch (error) {
            console.error("Error submitting address details:", error);
        }
    };

    const handleOpenModal = (itemId) => {
        setItemToDelete(itemId);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setItemToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (itemToDelete !== null) {
            await deleteItem(cart_id, itemToDelete);
            handleCloseModal();
        }
    };

    const deleteItem = async (cartId, itemId) => {
        try {
            const formData = new FormData();
            formData.append("cart_id", cart_id ? cart_id : CartId);
            formData.append("cart_item_id", itemId);
            await dispatch(removeItemApi(formData));
            showToast("Item removed successfully", 1);
            dispatch(filterCartApi({ cart_id: cart_id ? cart_id : CartId }));
        } catch (error) {
            console.error("Error removing item:", error);
        }
    }

    const dispatchOrders = async() => {
        const data = {
          cart: cart_id || cart?.id,
          purpose: purpose,
        };
        try {
            const response = await dispatch(diapatchOrder(data));         
            if (response?.payload?.status === 'success') {
                handleOpen()
            } else {
                console.error('error');
            }
        } catch (error) {
            console.error('error:', error);
        }
      };

    return (
        <>
        <Body>
            <Stack
                direction="row"
                spacing={10}
                sx={{ my: 2 }}
                justifyContent="space-between"
                alignItems={"center"}
            >
                <Stack direction="row" alignItems="center">
                    <img src={boxsvg} alt="Meter" />
                    <Typography
                        color={"#05141F"}
                        fontSize={"17px"}
                        fontWeight={400}
                        marginInlineStart={"10px"}
                        fontFamily={"'KiaSignatureRegular' !important"}
                    >
                        Your Order
                    </Typography>
                </Stack>
            </Stack>
            <Box>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                ) : data?.length > 0 ? (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className="d-flex">
                                <TableContainer className='complex-custom-table' component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                                    <Table aria-label="customized table" >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell sx={{ background: "#05141F !important" }}>Category</StyledTableCell>
                                                <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Supplier</StyledTableCell>
                                                <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Product</StyledTableCell>
                                                <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Quantity</StyledTableCell>
                                                <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Price</StyledTableCell>
                                                <StyledTableCell sx={{ background: "#05141F !important" }} align="center"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >


                                            {quantityLoading ? <>
                                                <StyledTableRow>
                                                    <StyledTableCell colSpan={6} align="center">
                                                        <CircularProgress />
                                                    </StyledTableCell>
                                                </StyledTableRow>

                                            </> : cartItems.map((item) => (

                                                <StyledTableRow key={item.id}>
                                                    <StyledTableCell align="center">{item.category}</StyledTableCell>
                                                    <StyledTableCell align="center">{item.supplier}</StyledTableCell>
                                                    <StyledTableCell align="center" className="inner-table-cell">
                                                        <Box className="inner-table">
                                                            <Typography variant='h4' className='fs-14 fw-300'><span style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>{item.product_description}</span></Typography>
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" className="inner-table-cell">
                                                        <Box className="inner-table">
                                                            <Box className='container-inner-table'>
                                                                <span className='m-auto d-flex gap-10'>
                                                                    <Button onClick={() => handleDecrement(item.id, item?.quantity)}><RemoveIcon /></Button>
                                                                    <OutlinedInput
                                                                        value={item?.quantity}
                                                                        onChange={(e) => handleChange(item.id, e.target.value)}
                                                                        onBlur={handleUpdate}

                                                                    />
                                                                    <Button onClick={() => handleIncrement(item.id, item?.quantity )}><AddIcon /></Button>
                                                                    {/* <Button className='fs-14 fw-300 update-btn' onClick={() => updateQuantity(item.id, quantities[item.id] || 1)}>Update</Button> */}
                                                                </span>
                                                            </Box>
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" className="inner-table-cell">
                                                        <Box className="inner-table">
                                                            <Typography variant='h4' className='fs-14 fw-300'><span>₹ {item.vendor_price}</span></Typography>
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" className="inner-table-cell">
                                                        <Typography variant='h4' className='fs-14 fw-300'>
                                                            <Button className='fs-14 fw-300 delete-btn'

                                                                onClick={() => handleOpenModal(item.id)}>
                                                                <img src={deletesvg} alt="Delete" />
                                                            </Button>
                                                        </Typography>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                            <StyledTableRow key={"subtotal"}>
                                                <StyledTableCell colSpan={3}></StyledTableCell>
                                                <StyledTableCell colSpan={3}>
                                                    <Box className="flex-wrap gap-30 justify-center subtotal">
                                                        <Typography variant='h4' className='fs-15 fw-300'>Subtotal</Typography>
                                                        <Typography variant='h4' className='fs-15 fw-600'>₹ {subtotal}</Typography>
                                                    </Box>

                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={3}>
                                                    <Typography varient='h4' className='fs-15 fw-300 mb-10'><BorderColorOutlinedIcon className='fs-15' />&nbsp; Please mention purpose of your order</Typography>
                                                    <FormControl className='w-50' variant="outlined">
                                                        <OutlinedInput
                                                            className='no-legend'
                                                            id="outlined-adornment"
                                                            value={purpose}
                                                            onChange={(e) => setPurpose(e.target.value)}
                                                            placeholder=""
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label="toggle password visibility" edge="end">
                                                                        <CheckCircleIcon sx={{ color: "#D2DEE8 !important" }} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />

                                                    </FormControl>
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={3}>

                                                    <Box className="flex-wrap gap-30 justify-center total">
                                                        <Typography variant='h4' className='fs-15 fw-300'>Total</Typography>
                                                        <Typography variant='h4' className='fs-15 fw-600'>₹ {total} (*Taxes Excluded)</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant='h4' className='fs-13 fw-700 mb-5 text-lightgray'>Note:</Typography>
                                                        <Typography variant='h4' className='fs-10 mb-3 text-lightgray d-flex'><FiberManualRecordIcon className='fs-8 mr-25' /> This price is exclusive of taxes and other charges.</Typography>
                                                        <Typography variant='h4' className='fs-10 mb-3 text-lightgray d-flex'><FiberManualRecordIcon className='fs-8 mr-25' /> You will receive quotation against this purchase order with charges and will be inclusive of all taxes</Typography>
                                                        <Typography variant='h4' className='fs-10 mb-3 text-lightgray d-flex'><FiberManualRecordIcon className='fs-8 mr-25' /> In case of confusion or queries related to items in cart and quantity, please contact dealer development team for assistance.</Typography>
                                                    </Box>
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} className="flex-wrap justify-content-end">
                                {/* <Box className="gap-10 flex-wrap justify-content-center">
                                    <Button className="back-btn" onClick={() => navigate(-1)}>Back</Button>
                                    <Button className="white-btn" onClick={() => navigate("/order/material-selection")}>Add More material</Button>
                                </Box> */}
                                <Box className="gap-10 flex-wrap justify-content-center">
                                    <Button className="link-btn-underline" onClick={() => navigate("/")}>Cancel</Button>
                                    <Button className="black-btn" onClick={dispatchOrders}>Update & Place Order</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Box sx={{ textAlign: 'center', my: 4 }}>
                        <Typography variant='h6' color='textSecondary'>No Item added</Typography>
                        <Button className="white-btn" onClick={() => navigate("/order/material-selection")}>Add More Material</Button>
                    </Box>
                )}
            </Box>
        </Body>

        {/* <RequiredMaterial open={open} handleClose={handleClose} purpose={purpose} /> */}
        <OrderSuccessfull open={open} handleClose={handleClose} />
        <DeleteConfirmationModal open={openModal} handleClose={handleCloseModal}
            handleConfirm={handleConfirmDelete}
            deleteType='Dealer' />
    </>

    )
}
export default CheckCart