import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const RejectQuotationApi = createAsyncThunk(
    'orders/reject',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/order/disapprove_quotation_by_dealer/', formData,{
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }  
)

const rejectQuotationSlice = createSlice({
    name: 'orders',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
    
        builder.addCase(RejectQuotationApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(RejectQuotationApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(RejectQuotationApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });
    },
})

export const rejectQuotationReducer = rejectQuotationSlice.reducer