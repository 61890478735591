import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const checkOrderStatus = createAsyncThunk(
    'order/checkOrderStatus', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/order/order-check/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            
            if(response.data.length === 0) {
                return rejectWithValue("Cart is empty");
            }
            return response.data;
        } catch (error) {

            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const resetOrderStatus = createAction('order/reset-order-status')


const checkOrderStatusSlice = createSlice({
    name: 'checkOrderStatus',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(checkOrderStatus.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(checkOrderStatus.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(checkOrderStatus.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.result = []
            state.message = action?.payload?.message
        })
        builder.addCase(resetOrderStatus, (state, action) => {
            state.result = []
            state.loading = false
            state.error = null
            state.message = ""
        })
    },
})

export const checkOrderStatusReducer = checkOrderStatusSlice.reducer